import React, { useState, useEffect, useRef, Fragment } from "react"
import cx from "classnames"
import CountUp from "react-countup"
import loadable from "@loadable/component"
import { InView } from "react-intersection-observer"

import Layout from "../components/layout"
import { IMG } from "../const"
import { AWARDS, LIST_OPTIONS, Picture } from "../data/about"
import { CUSTOMERS } from "../data/home-page"
import "../assets/styles/commons/_common-kit.scss"
import "../assets/styles/commons/_dash-before-text.scss"
import "../assets/styles/commons/_customers-logo__section.scss"
import * as styles from "../assets/styles/about.module.scss"
import Globe from "../components/globe"
import AwardsCarousel from "../components/carousels/AwardsCarousel"

const ContactForm = loadable(() => import("../components/contact-form"))

const AboutPage = () => {
  const [playMap, setPlayMap] = useState(false)
  const mapBlock = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const mapAnimationDuration = 7

  useEffect(() => {
    const handleScroll = () => {
      const mapBlockOffset = mapBlock.current.getBoundingClientRect()
      const mapPosition = mapBlockOffset.top - 500
      if (!playMap && mapPosition < 0) {
        setPlayMap(true)
      }
    }
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, []) // eslint-disable-line

  const onCountUpEnd = () => {
    let spans = document.querySelectorAll(".plusSpan")

    spans.forEach(span => {
      span.innerText = "+"
    })
  }

  return (
    <Layout
      pageTitle="About Us"
      metaDescription="Discover the LaSoft story. Learn about our passion for technology, expertise, and commitment to your success."
      defaultImage={`${IMG.THUMBNAILS}/thumbnail-about-us.jpg`}
    >
      {({
        width,
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <>
            <main className={cx(styles.aboutBlock, "common-kit")}>
              <article>
                <div className={styles.heading} id="about-us-section">
                  <div className={styles.heading__grid}>
                    <p className={cx(styles.leftText, styles.large)}>
                      We design, build, and maintain web and mobile apps for
                      startups, SMBs, and enterprise companies worldwide.
                    </p>
                    <h1>
                      About <strong>Us</strong>
                    </h1>

                    <div className={styles.rightText}>
                      <p>
                        We have been a trusted technology partner since 2014.
                      </p>
                    </div>
                  </div>
                  <div className={styles.heading__imgWrapper}>
                    <div className={styles.pic__1}>
                      <Picture
                        number={1}
                        width="434"
                        height="434"
                        altText="LaSoft development team"
                        desktopBreakpoint={901}
                      />
                    </div>
                    <div className={styles.pic__2}>
                      <Picture
                        number={2}
                        width="664"
                        height="334"
                        altText="LaSoft people are watching the funny photos of holiday party"
                        desktopBreakpoint={901}
                      />
                    </div>
                  </div>
                </div>
                <div className="common__dash-before-text">
                  <p>
                    Mission: We bring ideas to life by building a quality
                    software solution with our highly professional teams to
                    develop your business and enable its digital transformation
                  </p>
                </div>

                <div className={styles.ourStory__bg}>
                  <section className={styles.ourStory} id="OurStory">
                    <div>
                      <div className={styles.ourStory__title}>
                        <p className={styles.large}>from 2014</p>
                        <h2>
                          <strong>Our</strong> story
                        </h2>
                        <p>till the future</p>
                      </div>
                      <div className={styles.twoColumn}>
                        <div className={styles.imgBlock}>
                          <Picture
                            number={3}
                            width="605"
                            height="432"
                            altText="Discussion of the project at the LaSoft office"
                            desktopBreakpoint={901}
                          />
                        </div>
                        <div className={styles.textBlock}>
                          <p>
                            LaSoft's journey began in 2014 when our visionary
                            founders, driven by a common purpose, established
                            the company and embarked on a mission to address
                            business challenges through IT solutions. In those
                            early days, the company consisted of only a handful
                            of dedicated employees, including its founders, who
                            worked harmoniously in one space.
                          </p>
                          <p>
                            Fueled by a commitment to excellence and a growing
                            roster of satisfied clients, the company experienced
                            rapid expansion. This expansion is marked by a
                            global presence, with offices now established in
                            both Poland and the United States.
                          </p>
                          <p>
                            Today, LaSoft stands as a global entity, uniting 120
                            like-minded individuals with a singular mission. A
                            testament to its commitment to social impact is the
                            LaSoft IT academy, dedicated to nurturing and
                            employing talented individuals.
                          </p>
                          <p>
                            As our narrative continues to unfold, the company
                            remains steadfast in its dedication to enhancing and
                            broadening the customer service experience,
                            continually delivering exceptional digital solutions
                            to the world.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <section
                  className={styles.aboutMap}
                  ref={mapBlock}
                  id="section-values"
                >
                  <div className={styles.aboutMap__content}>
                    <h2>LaSoft coverage map</h2>

                    <div className={styles.counter__wrapper}>
                      <div className={styles.counter}>
                        {playMap && (
                          <CountUp
                            start={0}
                            end={85}
                            delay={0}
                            duration={mapAnimationDuration}
                            useEasing={false}
                          >
                            {({ countUpRef }) => (
                              <strong className={styles.number}>
                                <span ref={countUpRef} />
                                <span className="plusSpan" />
                              </strong>
                            )}
                          </CountUp>
                        )}
                        <small>Projects Delivered</small>
                      </div>
                      <div className={styles.counter}>
                        {playMap && (
                          <CountUp
                            start={0}
                            end={35}
                            delay={0}
                            duration={mapAnimationDuration}
                            useEasing={false}
                          >
                            {({ countUpRef }) => (
                              <strong className={styles.number}>
                                <span ref={countUpRef} />
                                <span className="plusSpan" />
                              </strong>
                            )}
                          </CountUp>
                        )}
                        <small>Cities Worldwide</small>
                      </div>
                      <div className={styles.counter}>
                        {playMap && (
                          <CountUp
                            start={0}
                            end={500}
                            delay={0}
                            duration={mapAnimationDuration}
                            useEasing={false}
                            onEnd={onCountUpEnd}
                          >
                            {({ countUpRef }) => (
                              <strong className={styles.number}>
                                <span ref={countUpRef} />
                                <span className="plusSpan" />
                              </strong>
                            )}
                          </CountUp>
                        )}
                        <small>Digital Business Cases Discovered</small>
                      </div>
                    </div>

                    <InView onChange={inView => setIsVisible(inView)}>
                      {({ ref }) => (
                        <div className={styles.globeBox} ref={ref}>
                          {isVisible ? <Globe /> : null}
                        </div>
                      )}
                    </InView>
                  </div>
                </section>

                <section className={styles.aboutUs} id="WhyLaSoft">
                  <div className={cx(styles.aboutUs__text)}>
                    <div>
                      {LIST_OPTIONS.map(item => (
                        <Fragment key={item.id}>
                          <h3>{item.title}</h3>
                          <p>{item.text}</p>
                        </Fragment>
                      ))}
                    </div>
                  </div>
                  <div className={styles.imgCityBackground}>
                    <picture>
                      <source
                        media="(max-width: 480px)"
                        srcSet={`
											${IMG.ABOUT}/city/480.avif,
											${IMG.ABOUT}/city/960.avif 2x,
											${IMG.ABOUT}/city/1440.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(max-width: 480px)"
                        srcSet={`
											${IMG.ABOUT}/city/480.webp,
											${IMG.ABOUT}/city/960.webp 2x,
											${IMG.ABOUT}/city/1440.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(max-width: 480px)"
                        srcSet={`
											${IMG.ABOUT}/city/480.jpg,
											${IMG.ABOUT}/city/960.jpg 2x,
											${IMG.ABOUT}/city/1440.jpg 3x`}
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`
											${IMG.ABOUT}/city/960.avif,
											${IMG.ABOUT}/city/1920.avif 2x,
											${IMG.ABOUT}/city/2880.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`
											${IMG.ABOUT}/city/960.webp,
											${IMG.ABOUT}/city/1920.webp 2x,
											${IMG.ABOUT}/city/2880.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`
											${IMG.ABOUT}/city/960.jpg,
											${IMG.ABOUT}/city/1920.jpg 2x,
											${IMG.ABOUT}/city/2880.jpg 3x`}
                      />
                      <img
                        alt="Lviv from a bird’s eye view"
                        src={`${IMG.ABOUT}/city.jpg`}
                        width="288"
                        height="324"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </section>

                <section
                  className={cx(
                    styles.logoBlock,
                    "section customers-logo__section"
                  )}
                  id="OurClients"
                >
                  <h2>Our customers</h2>
                  <p>
                    We sincerely care about every step that we take on the way
                    to reaching your unique goals. It is our pleasure to see our
                    clients’ wins and to support them in times of difficulty.
                    You can take a look at projects that we have worked on and
                    get to know our clients better below.
                  </p>

                  <ul className="customers-list" id="Customers">
                    {CUSTOMERS.map(customer => (
                      <li key={customer.name}>
                        <img
                          src={`${IMG.CUSTOMERS_LOGO}/grey/${customer.image}.svg`}
                          alt={customer.name}
                          width="180"
                          height="80"
                          loading="lazy"
                        />
                      </li>
                    ))}
                  </ul>
                </section>

                <section className={styles.aboutAwards__wrapper}>
                  <div
                    className={cx(styles.aboutAwards, "section")}
                    id="Awards"
                  >
                    <h2>Awards</h2>
                    <AwardsCarousel screenWidth={width}>
                      {AWARDS.map(item => (
                        <div key={item.id} className={styles.awardCard}>
                          <a href={item.url} target="_blank" rel="noreferrer">
                            <picture>
                              <source
                                srcSet={`${IMG.ABOUT}/${item.image}.avif`}
                                type="image/avif"
                              />
                              <source
                                srcSet={`${IMG.ABOUT}/${item.image}.webp`}
                                type="image/webp"
                              />
                              <img
                                src={`${IMG.ABOUT}/${item.image}.png`}
                                alt={item.altText}
                                width="150"
                                height="150"
                                loading="lazy"
                              />
                            </picture>
                            <h3>{item.title}</h3>
                          </a>
                        </div>
                      ))}
                    </AwardsCarousel>
                  </div>
                </section>

                <section
                  className={cx(styles.aboutSteps, "section")}
                  id="OurOffice"
                >
                  <div className={styles.aboutSteps__row1}>
                    <div>
                      <h2>Step into our office</h2>
                      <p>
                        Walking around our office, you will undoubtedly meet
                        many inspired people with glints in their eyes who
                        clearly understand their mission. Everyone feels at home
                        and likes to help our guests feel the same. The
                        atmosphere within the office is friendly and cozy.
                        Sometimes after a workday, we love to spend time
                        together watching a movie or arranging parties. We
                        frequently have the honor to greet our clients as
                        guests, so you are always welcome!
                      </p>
                    </div>
                    <Picture
                      number={4}
                      width="593"
                      height="587"
                      altText="LaSoft development team"
                      desktopBreakpoint={768}
                    />
                  </div>
                  <div className={styles.aboutSteps__row2}>
                    <Picture
                      number={5}
                      width="591"
                      height="284"
                      altText="LaSoft people are watching the funny photos of holiday party"
                      desktopBreakpoint={768}
                    />

                    <div>
                      <Picture
                        number={6}
                        width="285"
                        height="283"
                        altText="LaSoft development team"
                        desktopBreakpoint={768}
                      />
                      <Picture
                        number={7}
                        width="285"
                        height="283"
                        altText="LaSoft QA engineer"
                        desktopBreakpoint={768}
                      />
                    </div>
                  </div>
                </section>
              </article>
            </main>
            <section className="footerForm common-kit">
              <div className="section">
                <ContactForm
                  formStatID="CommonFooter"
                  handleOpenConfirmation={handleOpenConfirmation}
                  isOpenConfirmation={isOpenConfirmation}
                  setConfirmationOption={setConfirmationOption}
                />
              </div>
            </section>
          </>
        )
      }}
    </Layout>
  )
}

export default AboutPage
